
import React from 'react';
import BootstrapCarousel from './BootstrapCarousel';
import "./HomeStyles.css";
import Body from './body/Body';



const Home = () => {
  return (
    <>
    <div className="home">
    <BootstrapCarousel />
    
    <Body />
    </div>
    
    
    
    </>

  );
  
  
};

export default Home;