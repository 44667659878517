import React from 'react';
import "./ContactStyles.css"

const Contact = () => {
  return (
    <div className="contact">
    <div className="containe">
      <div className="content">
        <div className="left-side">
          <div className="address_details">
            <i className="fas fa-map-marker-alt"></i>
            <div className="topic">Address</div>
            <div className="text-one">16B, 1st floor BK market,</div>
            <div className="text-two">camac street,</div>
            <div className="text-two">Shakespear Sarani, kolkata-71</div>
          </div>

          <div className="phone_details">
            <i className="fas fa-phone-alt"></i>
            <div className="topic">Phone</div>
            <div className="text-one">+91-9831797544</div>
            <div className="text-two">+91-9836863904</div>
          </div>

          <div className="email_details">
            <i className="fas fa-envelope"></i>
            <div className="topic">email</div>
            <div className="text-one">surja@rebondreality.com</div>
            <div className="text-two">info@rebondreality.com</div>
          </div>

        </div>
      

      </div>
    </div>
    </div>
    );
  
};

export default Contact;