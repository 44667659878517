import React, { Component } from "react";
import Carousel from "react-bootstrap/esm/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BootstrapCarouselStyles.css";

export class BootstrapCarousel extends Component {
  
  onClickItemEvent = () => {
    console.log('onClickItem Event Triggered');
}
    render() {  

            return (  
              <div className='container-fluid' >  
                         <Carousel fade autoPlay interval="2000" transitionTime="5000"
                         onClickItem={this.onClickItemEvent}
                         >  
                         <Carousel.Item style={{'height':"400px"}} >  
                         <a href="/"><img style={{'height':"400px"}}  
                         className="d-block w-100"  
                        src={'rg.jpg'} alt="royal ganges"  />  </a>
                           <Carousel.Caption>  
                             <h1>The Royal Ganges </h1>
                             <p>The River and You</p>  
                                 </Carousel.Caption>  
                                 </Carousel.Item  >  
                                 <Carousel.Item style={{'height':"400px"}}>  
                                 <a href="/"><img style={{'height':"400px"}}  
                                   className="d-block w-100"  
                                    src={'utalika.jpg'} alt="utalika"
                                    onClickItem={this.onClickItemEvent}   /></a>  
                                       <Carousel.Caption>  
                                   <h1>Utalika Luxury Phase II</h1>  
                                      </Carousel.Caption>  
                                         </Carousel.Item>  
                                       <Carousel.Item style={{'height':"400px"}}>  
                                       <a href="/"><img style={{'height':"400px"}}  
                                        className="d-block w-100"  
                                         src={'vaanya.jpg'} alt="vaanya"  /> </a> 
                                        <Carousel.Caption>  
                                          <h1>VAANYA</h1>
                                          <p>Urban Forest Homes</p> 
                                          </Carousel.Caption>  
                                         </Carousel.Item>
                                         <Carousel.Item style={{'height':"400px"}}>  
                                       <a href="/"><img style={{'height':"400px"}}  
                                        className="d-block w-100"  
                                         src={'siddasky.jpg'} alt="siddhasky"  /> </a> 
                                        <Carousel.Caption>  
                                          <h1>Siddhasky</h1> 
                                          <p>If there is Heaven it's Here</p> 
                                          </Carousel.Caption>  
                                         </Carousel.Item>
                                         <Carousel.Item style={{'height':"400px"}}>  
                                       <a href="/"><img style={{'height':"400px"}}  
                                        className="d-block w-100"  
                                         src={'avidipta.jpg'} alt="avidipta"  /> </a> 
                                        <Carousel.Caption>  
                                          <h1>AVIDIPTA</h1> 
                                          <p>It’s Beyond</p> 
                                          </Carousel.Caption>  
                                         </Carousel.Item>
                                         <Carousel.Item style={{'height':"400px"}}>  
                                       <a href="/"><img style={{'height':"400px"}}  
                                        className="d-block w-100"  
                                         src={'aurus.jpg'} alt="aurus"  /> </a> 
                                        <Carousel.Caption>  
                                          <h1>AURUS</h1>
                                          <p>A stellar residential park designed for world citizens</p>  
                                          </Carousel.Caption>  
                                         </Carousel.Item>  
                                        </Carousel>  
                                </div>   
                
                         
            );
    };
};

export default BootstrapCarousel;