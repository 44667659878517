import React from 'react';
import "./About.css";
const About = () => {
  return( 
    <div className="about">
      <h>Rebond Reality</h>
      <div className="founder">
      <img className="image" src="founder.jpg" alt="founder_img" />
      </div>
      <p5>Our Founder Mr. Surja Kanta Manna</p5>
      
      <p>As the main private property of the executive’s organization in kolkata, we endeavor to enable your locale to flourish.</p>

      <p>Our commitment, responsiveness, and respectability are why many networks over the kolkata to deal with their properties.</p>
      <p>‘Rebond Reality’ works with a wide range of properties:</p>
      <div className="pro">
      <li>Low, mid and skyscraper apartment suites and cooperatives</li>
      <li>Single-family property holders affiliations (HOAs)</li>
      <li>Vast scale ace arranged networks</li>
      <li>Way of life and dynamic grown-up networks</li>
      </div>
      <p>We likewise have an extraordinary aptitude for working with complex networks with huge spending plans and offering various enhancements to their occupants. Likewise, we oversee business affiliations and blended-use advancements, just as multi-family and financial specialists claimed rental and REO properties for institutional and private value customers.</p>
      <p>Our main goal is to convey excellent administration and arrangements that upgrade the estimation of each property and the way of life of each inhabitant in our consideration.</p>

      <p>By creating noteworthy contributions, ‘Rebond Reality’ has increased present expectations for quality and administration brilliance. What’s more, we constantly seek approaches to improve our administrations even.</p>

      <p>If you think an extensive property the executives firm won’t focus on your locale’s special needs, investigate ‘Rebond Reality’. When you choose to band together with us, the main thing we do is tune in.</p>

      <p>By tuning in to and teaming up with your board, we can redo our administration contributions and answers to address your locale’s issues, objectives, and vision.</p>

      <p>‘Rebond Reality’ also keeps a neighborhood nearness wherever we oversee networks. This implies that the individuals from your supervisory crew live in a similar city, town, or neighborhood.</p>

      <p>They comprehend the neighborhood culture, and they know which sellers and contractual workers are dependable and solid.</p>
      
      </div>
      
    );
  
};

export default About;