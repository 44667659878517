import React,{useEffect}from "react";
import './Footer.css';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";


import Aos from "aos";
import "aos/dist/aos.css";




const Footer = () =>{
    useEffect(()=>{
        Aos.init({duration:2000})
    },[]);
    
    return(
        
        <div className="footer">
            <div data-aos="fade-up" className="sub_footer">
                <div data-aos="fade-up" className="sub_footer_links">
                    <div data-aos="fade-up" className="sub_footer_links_div">
                        <h4>For Business</h4>
                        <a href="/"><p>Employer</p></a>
                        <a href="/"><p>Health plan</p></a>
                        <a href="/"><p>Individual</p></a>
                        
                    </div>
                    <div data-aos="fade-up" className="sub_footer_links_div">
                        <h4>Resources</h4>
                        <a href="/"><p>Resource</p></a>
                        <a href="/"><p>Testimonials</p></a>
                        
                    </div>
                    <div className="sub_footer_links_div">
                        <h4>Partners</h4>

                        <a href="/"><p>Partner</p></a>
                    </div>

                    <div data-aos="fade-up" className="sub_footer_links_div">
                        <h4>Company</h4>
                        <a href="/"><p>About</p></a>
                        <a href="/"><p>Career</p></a>
                        <a href="/"><p>Contact</p></a>
                    </div>

                    <div data-aos="fade-up" className="sub_footer_links_div">
                        <h4>connect us on</h4>
                        <div className="socialmedia">
                            <p><a href="https://www.facebook.com/search/top?q=rebond-reality"><FaFacebookF /></a></p>
                            <p><a href="https://www.instagram.com/rebondreality/"><FaInstagram /></a></p>
                            <p>+91-9831797544</p>
                            <p>+91-9836863904</p>
                        </div>
                    </div>


                </div>

                <hr></hr>
                <div className="sub_footer_below">
                    <div className="sub_footer_copyright">
                        <p>
                            @{new Date().getFullYear()} rebond reality.All right reserved.
                        </p>
                    </div>

                    <div className="sub_footer_below_links">
                        <a href="/"><p>Terms & Conditions</p></a>
                        <a href="/"><p>Privacy</p></a>
                        <a href="/"><p>Security</p></a>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    )
}
export default Footer;