
export const MenuData = [
    {
        title: "Home",
        url:"/",
        cName:"nav-links",
        icon:"fa-solid fa-house-user"
    },
    {
        title: "About",
        url:"/About",
        cName:"nav-links",
        icon:"fa-solid fa-circle-info"
    },
    {
        title: "Contact",
        url:"/Contact",
        cName:"nav-links",
        icon:"fa-solid fa-address-book"
    }
]