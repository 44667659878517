import React from "react";
import './App.css';
import Home from "./components/Home";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './components/Contact';
import Navbar from './components/Navbar/Navbar';
import About from './components/About';
import { Helmet, HelmetProvider } from "react-helmet-async";


function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Premium 2 BHK,3 BHK, 4 BHK Flats all over kolkata | Rebond Reality</title>
        <meta key="1"
          name="description"
          content="3620+ Premium 2 BHK,3 BHK, 4 BHK Flats / Apartments for Sale Off EM Bypass, New Town and all over kolkata with Modern Amenities."
         />
         <meta name="keywords" content="Flat, Apartments, Vaanya, Utalika, Avidipta, Aurous, Flat in kolkata, Royal ganges, Bungalows for sale in Kolkata, Bungalow, Morya, Premium 3 BHK Flats, Merlin, Premium 2 BHK Flats, Flora Fountain" 
         />
      </Helmet>

    
    

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          
          
        </Route>
        
      </Routes>
      
    </BrowserRouter>
    
    <Footer />
    

    
    </HelmetProvider>
  );
}

export default App;
