import React ,{useEffect} from "react";
import "./BodyStyles.css";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {HiOutlineClipboardCheck} from "react-icons/hi";

import Aos from "aos";
import "aos/dist/aos.css";



const Data =[
    {
        id:1,
        imgSrc: 'royalganges_nw.jpg',
        prjTitle:'The Royal Ganges',
        location:' Batanagar,Kolkata',
        grade:'A FINER RIVERFRONT RESIDENCE',
        Price:'50 Lac onwards',
        description:'India’s longest river side homes comprising of over 1.1 km long riverfront promenade & the closest bungalow property from Kolkata.'
    },
    {
        id:2,
        imgSrc: 'vaanya.jpg',
        prjTitle:'VAANYA',
        location:'Newtown',
        grade:'Vaanya is all that belongs to the forest',
        Price:'1.8 cr onwards',
        description:'Own spacious 3 BHK, 4 BHK and 5 BHK premium flats amidst acres of exotic greenery. '
    },
    {
        id:3,
        imgSrc: 'anantmani.jpg',
        prjTitle:'Anantmani',
        location:' Kankurgachi,kolkata',
        grade:'A Life Focused on the Infinite',
        Price:'2.5 cr onwards',
        description:'Welcome to a world that stands tall, Amidst the sky of North Kolkata, Conceived with a focus on daily life experiences.',
        
    },
    {
        id:4,
        imgSrc: 'morya.png',
        prjTitle:'Morya',
        location:'Tollygunge,Kolkata',
        grade:'Indian Soul, International Luxury with Flats in Tollygunge',
        Price:'1.2 cr onwards',
        description:'MORYA HAS THE WORLDS FINEST AMENITIES AND ARE THE BEST 3 & 4 BHK FLATS IN TOLLYGUNGE'
    },
    {
        id:5,
        imgSrc: 'avana.jpeg',
        prjTitle:'Avana',
        location:'Tollygunge,kolkata',
        grade:'Over 8 acres of uncluttered nature',
        Price:'65 Lac onwards',
        description:'Never miss out on moments as you envisage it to be, with canopy jogging track,co-working space apart from a host of other lifestyle amenities.'
    },
    {
        id:6,
        imgSrc: 'lagunabay.jpg',
        prjTitle:'Lagunabay',
        location:'Topsia,kolkata',
        grade:'Witness Your Dream Turning True',
        Price:'1.25 cr onwards',
        description:'Situated in E M Bypass, the Laguna is planned to offer a modern lifestyle to all the residents.'
    },
    {
        id:7,
        imgSrc: 'sanctuary.jpeg',
        prjTitle:'Sanctuary',
        location:'Tollygunge,kolkata',
        grade:'Sanctuary luxuriously combines well-being with sustainability',
        Price:'2.5 cr onwards',
        description:'The calm, green spaces of Sanctuary are a successful example of urban forestation and a guiding factor of its design.'
    },
    {
        id:8,
        imgSrc: 'atlantis.jpg',
        prjTitle:'Atlantis',
        location:'Action Area I,New Town',
        grade:'Rise above the rest',
        Price:'1.5 cr',
        description:'Life feels great when it is full of fun. And, life is going to be a real fun-filled experience at ATLANTIS'
    },
    {
        id:9,
        imgSrc: 'urvisha.jpg',
        prjTitle:'Urvisha-The Condoville',
        location:'New Town',
        grade:'HAND IN HAND TOWARDS A BEAUTIFUL FUTURE',
        Price:'1.6 cr onwards',
        description:'Urvisha-The Condoville- is an urban oasis, where the elements of a thriving metropolis and the charm of nature play out in synergy. '
    },
    {
        id:10,
        imgSrc: 'purtiveda.jpg',
        prjTitle:'Purti Veda',
        location:'Action Area I,New Town',
        grade:'Live amidst natural beauty',
        Price:'85 Lac onwards',
        description:'Veda is a residential development in Newtown that offers its residents a spectacular view, natural beauty, location convenience and a plethora of amenities.'
    },
    {
        id:11,
        imgSrc: 'rise.jpg',
        prjTitle:'Merlin RISE',
        location:'Rajarhat,kolkata',
        grade:'Merlin Rise in Kolkata brings you classy apartments',
        Price:'45 Lac onwards',
        description:'Buying an elegant apartment in Kolkata might be in your mind for a while. In case you have been looking for a classy home in this metro city.'
    },
    {
        id:12,
        imgSrc: 'dtc.jpg',
        prjTitle:'DTC Rjarhat',
        location:'Rajarhat,kolkata',
        grade:'To be unbound and Limitless is how we envision our lives',
        Price:'38 Lac onwards',
        description:'This futuristic residential complex is not just a home but also its a mark of excellence among the real estates projects of Kolkata. It will also be your gateway to a life bigger than you have ever imagined.'
    }
]

const Body = () =>{

    useEffect(()=>{
        Aos.init({duration:2000})
    },[]);



    return(
        <section className="body_container_section">
            <div className="secTitle">
                <h3 data-aos="fade-right" className="title">
                    Most Popular Projects
                </h3>
            </div>
             <div data-aos="fade-up" className="secContent_grid">
                {
                    Data.map(({id,imgSrc,prjTitle,location,grade,Price,description})=>{
                        return(
                            <div key={id} data-aos="fade-up" className="singleProject">
                                <div className="imageDiv">
                                    <img className="iimg" src={imgSrc} alt={prjTitle} />
                                </div >
                                <div className="cardInfo">
                                    <h1 className="prjTitle">{prjTitle}</h1>
                                    <span className="content_flex">
                                        <HiOutlineLocationMarker className="icon"/>
                                        <span className="name">{location}</span>

                                    </span>

                                    <div className="price_flex">
                                        <div className="grade">
                                            <span>{grade}<small>+12</small></span>
                                        </div>
                                            <div className="price">
                                                <h5>{Price}</h5>
                                            </div>
                                        </div>

                                        <div className="desc">
                                            <p>{description}</p>
                                        </div>
                                        <button className='btn_flex'>
                                            DETAILS <HiOutlineClipboardCheck className="icon"/>
                                        </button>

                                </div>

                            </div>
                        )

                    }
                    )
                }

             </div>

        </section>

    );
};

export default Body;